import React, {useEffect, useState} from "react";
import {collection, doc, getDoc, getDocs} from "firebase/firestore";
import {db} from "../Firebase/firebase";
import bgLogoHole from "../Images/bg-logo-hole.png";
import goldenImage from "../Images/goldern group.png";
import SEO from "../components/SEO";

const SchemePage = () => {

    const [news, setNews] = useState([]);


    const fetchNewsData = async () => {
        try {
            const collectionRef = collection(db, 'daily-news-web'); // Adjust this based on your Firestore collection name
            const querySnapshot = await getDocs(collectionRef);
            const newsData = [];

            querySnapshot.forEach((doc) => {
                const data = doc.data();
                const timestamp = data.timestamp.toDate();

                const options = {
                    hour: 'numeric',
                    minute: '2-digit',
                    month: 'long',
                    day: 'numeric',
                };

                const monthOption ={
                    month: 'long'
                }

                const dayOption ={
                    day: 'numeric',
                }

                const formattedTimestamp = new Intl.DateTimeFormat('en-US', options).format(timestamp);
                const formattedTimestampMonth = new Intl.DateTimeFormat('en-US', monthOption).format(timestamp);
                const formattedTimestampDay = new Intl.DateTimeFormat('en-US', dayOption).format(timestamp);


                newsData.push({
                    id: doc.id,
                    title: data.title,
                    content: data.content,
                    //change time format for below line
                    timestamp: formattedTimestamp, // Convert Firestore timestamp to JavaScript Date
                    month: formattedTimestampMonth,
                    day: formattedTimestampDay
                });
            });

            setNews(newsData);
        } catch (error) {
            console.error('Error fetching news: ', error);
        }
    };

    useEffect(() => {
        fetchNewsData()
    },[])

    const schemePageSchema = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Golden Group Scheme",
        "description": "Latest schemes and announcements from Golden Group",
        "publisher": {
            "@type": "Organization",
            "name": "DPANNA",
            "logo": "https://dpanna.in/logo192.png"
        }
    };

    return (
        <>
            <SEO 
                title="Golden Group Scheme"
                description="View the latest schemes, announcements and updates from Golden Group at DPANNA."
                keywords="Golden Group scheme, DPANNA announcements, latest updates"
                schema={schemePageSchema}
            />
            <div className="flex flex-col items-center bg-black min-h-screen text-white">
                <div className="relative w-full flex justify-center items-center pb-4">
                    <img src={bgLogoHole} alt="" className="absolute animate-pulse inset-0 w-full h-32 z-0"/>
                    <img src={goldenImage} alt="" className="relative h-28 z-20"/>
                </div>
                {/*<hr className="w-full my-1 border-t-2 border-yellow-400" />*/}
                <div className="welcome-banner text-center">
                    <h2 className="text-3xl  font-bold">Welcome to</h2>
                    <h1 className="text-3xl text-yellow-300 font-extrabold">GOLDEN GROUP</h1>
                </div>
                <hr className="w-full my-1 border-t-2 border-yellow-400"/>

                <div className={"text-5xl p-1 font-bold"}>
                    Scheme
                </div>

                {news
                    .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                    .map(item => (
                        <div key={item.id} className="mb-8 sm:w-[50%]">
                            <div className="border border-yellow-400 p-4 rounded-lg">
                                <div className="flex items-center justify-between mb-4">
                                    <div className="text-3xl font-bold">{item.title}</div>
                                    <div className="text-2xl font-semibold">{item.timestamp}</div>
                                </div>
                                <div className="text-lg">{item.content}</div>
                            </div>
                        </div>
                    ))}
            </div>
        </>
    )

}

export default SchemePage