import React, { useEffect } from 'react';

const SEO = ({ title, description, keywords, schema }) => {
  useEffect(() => {
    // Update document title
    document.title = title || "DPANNA - Golden Group Results";

    // Update meta tags
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', description || "Get the latest Golden Group results, lucky numbers, and daily updates at DPANNA.");
    }

    const metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.setAttribute('content', keywords || "DPANNA, Golden Group, matka, lucky numbers, Mumbai, Delhi");
    }

    // Handle schema data
    let scriptTag = document.querySelector('#schema-script');
    if (schema) {
      if (!scriptTag) {
        scriptTag = document.createElement('script');
        scriptTag.id = 'schema-script';
        scriptTag.type = 'application/ld+json';
        document.head.appendChild(scriptTag);
      }
      scriptTag.textContent = JSON.stringify(schema);
    } else if (scriptTag) {
      scriptTag.remove();
    }

    // Cleanup function
    return () => {
      if (scriptTag) {
        scriptTag.remove();
      }
    };
  }, [title, description, keywords, schema]);

  return null;
};

export default SEO;
