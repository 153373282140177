import React, {useEffect, useState} from "react";
import { collection, query, where, getDocs , doc, getDoc} from "firebase/firestore"; // Import necessary Firestore functions
import {db} from "../Firebase/firebase";
import bgLogoHole from "../Images/bg-logo-hole.png";
import goldenImage from "../Images/goldern group.png";
import whatsAppLogo from "../Images/whatsapp.png";
import telegramLogo from "../Images/telegramlogo.png";
import border from "../Images/border.jpeg";
import {Link} from "react-router-dom"
import "./mainPage.css";
import SEO from "../components/SEO";
import SEOKeywords from "../components/SEOKeywords";


export default function MainPage(){
    const [draws, setDraws] = useState([]);
    const [delhiPatternDraws, setDelhiPatternDraws] = useState([]);
    const [mumbaiPatternDraws, setMumbaiPatternDraws] = useState([]);
    const [loading, setLoading] = useState(false);
    const [topSixMumbaiDraws, setTopSixMumbaiDraws] = useState([]);
    const [todaysDraws, setTodaysDraws] = useState([]);
    const [upcomingDraws, setUpcomingDraws] = useState([]);
    const [luckyNumbersList, setLuckyNumbersList] = useState([]);
    const [whatsappNumbersList, setWhatsAppNumbersList] = useState([]);
    const [config, setConfig] = useState({});
    const [highlightedDraws, setHighlightedDraws] = useState([]);

    const [pattiNumbersList, setPattiNumbersList] = useState([]);


    useEffect(() => {
        // Combine both config fetches into one
        const initializeApp = async () => {
            await fetchConfig();
            await fetchConfig1();
        };
        initializeApp();
    }, []); // This will run once on mount

    useEffect(() => {
        // Only fetch draws if we have config loaded
        if (Object.keys(config).length > 0) {
            fetchDraws();
        }
    }, [config]); // Only re-run if config changes

    useEffect(() => {
        if (draws.length > 0) {
            fetchTodaysDraws();
        }
    }, [draws]);

    useEffect(() => {
        if (todaysDraws.length > 0) {
            fetchUpcomingDraws();
        }
    }, [todaysDraws]);

    useEffect(() => {
        if (config && mumbaiPatternDraws.length > 0) {
            getTopSixMumbaiDraws();
        }
    }, [config, mumbaiPatternDraws]);

    useEffect(() => {
        fetchHighlightedDraws();
    }, []);

    const convertStringToTime = (inputString) => {
        const [hours, minutes] = inputString.split(":").map(Number);
        if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
            throw new Error("Invalid time values");
        }
        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const generateDatesToFetch = (currentDate, daysBehind, daysAhead) => {
        const dates = [];
        for (let i = daysBehind; i >= -daysAhead; i--) {
            const date = new Date(currentDate);
            date.setDate(currentDate.getDate() - i);
            dates.push(formatDate(date));
        }
        return dates;
    };

    const fetchDraws = async () => {
        try {

            const currentDate = new Date();
            const datesToFetch = generateDatesToFetch(currentDate, 1, 1).map(date => date.replace(/-/g, "/"));

            const response = await fetch("https://dp-anna-api.web.app/api/fetchDraws1");
            const allDrawsData = await response.json().then(data => data.data);
// Fetch the data from the API
            const responsePatternDraws = await fetch("https://dp-anna-api.web.app/api/fetchPatternDraws1");
            const patternDrawsDataResponse = (await responsePatternDraws.json()).data;
// Destructure the arrays from the response
            const { mumbaiPatternDraws, delhiPatternDraws } = patternDrawsDataResponse;
// Process the Mumbai draws data by filtering and sorting
            const mumbaiPatternDrawsData = mumbaiPatternDraws
                .filter(draw => config.draws_to_show.includes(draw.id))
                .sort((a, b) => convertStringToTime(a.startTime) - convertStringToTime(b.startTime));
// Process the Delhi draws data by sorting
            const delhiPatternDrawsData = delhiPatternDraws
                .sort((a, b) => convertStringToTime(a.startTime) - convertStringToTime(b.startTime));

            setMumbaiPatternDraws(mumbaiPatternDrawsData);
            setDelhiPatternDraws(delhiPatternDrawsData);

            const mumbaiDraws = allDrawsData.filter(draw => draw.pattern === "mumbaiPattern").sort((a, b) =>
                convertStringToTime(a.startTime) - convertStringToTime(b.startTime)
            );

            const delhiDraws = allDrawsData.filter(draw => draw.pattern === "delhiPattern").sort((a, b) =>
                convertStringToTime(a.startTime) - convertStringToTime(b.startTime)
            );

            const fetchedDraws = datesToFetch.map(date => ({
                date,
                mumbaiDraws: mumbaiDraws.filter(draw => draw.date === date),
                delhiDraws: delhiDraws.filter(draw => draw.date === date)
            })).reverse();

            setDraws(fetchedDraws);

        } catch (error) {
            console.error('Error fetching draws:', error);
        }
    };

    const getTopSixMumbaiDraws = () => {
        if (config && config.top_draws_to_show && Array.isArray(config.top_draws_to_show)) {
            const drawIds = config.top_draws_to_show;
            const topDraws = drawIds
                .map(id => mumbaiPatternDraws.find(draw => draw.id === id))
                .filter(Boolean)
                .sort((a, b) => convertStringToTime(a.startTime) - convertStringToTime(b.startTime));
            console.log("Top Draws:", topDraws);
            setTopSixMumbaiDraws(topDraws);
        } else {
            console.error("Invalid config or top_draws_to_show is not an array");
        }
    };

    const fetchUpcomingDraws = () => {
        if (todaysDraws.length) {
            const currentTime = new Date();
            const timeThreshold = 30 * 60 * 1000; // 30 minutes

            const filterUpcomingDraws = (draws) => draws.filter(draw => {
                const startTime = convertStringToTime(draw.startTime);
                const closingTime = convertStringToTime(draw.closingTime);
                const openingDifference = startTime - currentTime;
                const closingDifference = closingTime - currentTime;

                return (openingDifference <= timeThreshold && openingDifference > -timeThreshold) ||
                    (closingDifference <= timeThreshold && closingDifference > -timeThreshold);
            }).map(draw => ({
                ...draw,
                selectedDraw: convertStringToTime(draw.startTime) - currentTime <= timeThreshold ? "opening" : "closing"
            }));

            const uniqueDraws = (draws) => [...new Map(draws.map(draw => [draw.id, draw])).values()];

            setUpcomingDraws(uniqueDraws(filterUpcomingDraws(mumbaiPatternDraws)));
        }
    };

    const fetchTodaysDraws = () => {
        const currentDate = new Date();
        const formattedCurrentDate = formatDate(currentDate).replace(/-/g, "/");
        const formattedYesterdayDate = formatDate(new Date(currentDate.setDate(currentDate.getDate() - 1))).replace(/-/g, "/");

        let currentDayDraws = draws.find(draw => draw.date === formattedCurrentDate)?.mumbaiDraws || [];
        if (!currentDayDraws.length) {
            currentDayDraws = draws.find(draw => draw.date === formattedYesterdayDate)?.mumbaiDraws || [];
        }

        let currentDayDrawsDelhi = draws.find(draw => draw.date === formattedCurrentDate)?.delhiDraws || [];
        if (!currentDayDrawsDelhi.length) {
            currentDayDrawsDelhi = draws.find(draw => draw.date === formattedYesterdayDate)?.delhiDraws || [];
        }

        currentDayDraws.sort((a, b) => convertStringToTime(a.startTime) - convertStringToTime(b.startTime));
        setTodaysDraws([{ date: formattedCurrentDate, mumbaiDraws: currentDayDraws, delhiDraws: currentDayDrawsDelhi }]);
    };

    const mapKeys = (obj) => {
        const newObj = {};
        for (const key in obj) {
            const newKey = key.replace(/-/g, '_');
            if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                newObj[newKey] = mapKeys(obj[key]);
            } else {
                newObj[newKey] = obj[key];
            }
        }
        return newObj;
    };
    const fetchConfig = async () => {
        try {
            const configDocSnapshot = await getDoc(doc(db, 'config', 'dpanna-main'));
            if (configDocSnapshot.exists()) {
                const configData = configDocSnapshot.data();
                const mappedConfigData = mapKeys(configData);

                // Transform the whatsAppNumbers map to an array of objects
                const numbersList = Object.entries(mappedConfigData.whatsAppNumbers).map(([name, number]) => ({
                    name,
                    number
                }));

                setWhatsAppNumbersList(numbersList);
                setConfig(mappedConfigData);
            } else {
                console.log('Document does not exist');
            }
        } catch (error) {
            console.error('Error fetching config:', error);
        }
    };

    const fetchConfig1 = async () => {
        try {
            const configDocSnapshot = await getDoc(doc(db, 'config', 'dpanna-golden-news'));
            if (configDocSnapshot.exists()) {
                const configData = configDocSnapshot.data();
                const mappedConfigData = mapKeys(configData);

                const luckyNumbersList = Object.entries(mappedConfigData.luckyNumbers).map(([title, content]) => ({
                    title,
                    content
                }))

                const pattiNumbersList = Object.entries(mappedConfigData.multipleNumbers || {}).map(([title, content]) => ({
                    title,
                    content
                }));

                const tripledPattiNumbersList = [...pattiNumbersList, ...pattiNumbersList];

                setPattiNumbersList(tripledPattiNumbersList);
                setLuckyNumbersList(luckyNumbersList);
            } else {
                console.log('Document does not exist');
            }
        } catch (error) {
            console.error('Error fetching config:', error);
        }
    };

    const fetchHighlightedDraws = async () => {
        try {
            const configDocRef = doc(db, 'config', 'dpanna-main');
            const configDocSnapshot = await getDoc(configDocRef);

            if (configDocSnapshot.exists()) {
                const configData = configDocSnapshot.data();
                const highlightedDrawsArray = configData['highlightedDraws'] || [];
                setHighlightedDraws(highlightedDrawsArray);
            }
        } catch (error) {
            console.error('Error fetching highlighted draws:', error);
        }
    };

    const refreshData = async () => {
        setLoading(true);
        await fetchDraws();
        fetchTodaysDraws();
        getTopSixMumbaiDraws();
        fetchUpcomingDraws();
        setLoading(false);
    };

    const LoadingSpinner = () => {
        return (
            <div className="flex justify-center backdrop-blur-lg backdrop-saturate-150 items-center z-50 fixed w-screen bg-black/30 h-screen">
                <div className="animate-spin rounded-full h-28 w-28 border-t-4 border-b-4 border-yellow-400"></div>
            </div>
        );
    };

    // Create schema for homepage
    const homeSchema = {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "name": "DPANNA",
        "url": "https://dpanna.in",
        "potentialAction": {
            "@type": "SearchAction",
            "target": "https://dpanna.in/search?q={search_term_string}",
            "query-input": "required name=search_term_string"
        }
    };

    return (
        <>
            <SEO 
                title="Golden Group Results & Lucky Numbers"
                description="Get the latest Golden Group results, lucky numbers, and daily updates at DPANNA - your trusted source for all updates."
                keywords="DPANNA, Golden Group results, today's lucky number, Mumbai bazaar, Delhi bazaar"
                schema={homeSchema}
            />
            {loading && <LoadingSpinner />}
            <div className="flex flex-col items-center bg-gradient-to-b from-gray-900 to-black min-h-screen text-white">
                {/* Logo Section with Enhanced Glow */}
                <div className="relative w-full flex justify-center items-center pb-4">
                    <div className="absolute inset-0 w-full h-32 z-0">
                        <img src={bgLogoHole} alt="" className="absolute animate-pulse w-full h-full"/>
                    </div>
                    {/* Glow effect layer */}
                    <div className="absolute z-10 w-28 h-28 rounded-full 
                        bg-yellow-300/30 blur-xl animate-pulse-slow"></div>
                    {/* Main logo with enhanced glow */}
                    <div className="relative z-20">
                        <img 
                            src={goldenImage} 
                            alt="" 
                            className="h-28 drop-shadow-[0_0_25px_rgba(255,223,0,0.15)]"
                        />
                    </div>
                </div>

                {/* Welcome Banner with Enhanced Styling */}
                <div className="welcome-banner text-center relative p-1 mx-4 my-2">
                    <div className="absolute inset-0">
                        <div className="absolute inset-0 border-2 border-yellow-700 opacity-80 
                            shadow-[0_0_15px_#e6c200,inset_0_0_15px_#e6c200] rounded-lg"></div>
                    </div>
                    <div className="relative z-10 bg-gradient-to-r from-gray-900 via-black to-gray-900 backdrop-blur-sm p-4 rounded-lg">
                        <h2 className="text-3xl font-bold text-white tracking-wide shadow-text">Welcome to</h2>
                        <h1 className="text-3xl text-yellow-400 font-extrabold mt-2 tracking-wider shadow-text-gold">DPANNA.IN</h1>
                    </div>
                </div>

                {/* Contact Button with Enhanced Styling */}
                <div className="text-center my-1 relative inline-block p-6 mx-4">
                    <Link to="/form" className="relative z-10">
                        <button className="bg-gradient-to-r from-yellow-600 to-yellow-800 
                            text-black font-bold py-3 px-6 rounded-lg 
                            transform transition-all duration-300 
                            hover:scale-105 hover:from-yellow-700 hover:to-yellow-900 
                            shadow-[0_0_20px_#e6c200] 
                            hover:shadow-[0_0_30px_#e6c200]">
                            हमसे संपर्क करने के लिए ➡️ यहाँ क्लिक करें
                        </button>
                    </Link>
                </div>

                {/* Lucky Numbers Section */}
                {luckyNumbersList.length > 0 &&
                    <div className={"w-full"}>
                        <h1 className={"text-center text-2xl font-bold text-yellow-400 sm:text-5xl tracking-wide"}>
                            Today's Lucky Number
                        </h1>
                        <div className="wrapper w-full">
                            <div className="marquee">
                                <MarqueeGroup isReverse={true} arrayOfObjects={luckyNumbersList}/>
                                <MarqueeGroup isReverse={true} arrayOfObjects={luckyNumbersList}/>
                            </div>
                        </div>
                    </div>
                }

                {/* Patti Numbers Section */}
                {pattiNumbersList.length > 0 &&
                    <div className={"w-full"}>
                        <div className="wrapper w-full">
                            <div className="marquee">
                                <PattiMarqueeGroup isReverse={false} arrayOfObjects={pattiNumbersList} />
                                <PattiMarqueeGroup isReverse={false} arrayOfObjects={pattiNumbersList} />
                            </div>
                        </div>
                    </div>
                }

                {/* Live Result Section with Enhanced Styling */}
                <div className="live-result text-center py-2 w-full max-w-7xl">
                    <div className="mt-2">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1">
                            {upcomingDraws.map(draw => (
                                <div key={draw.id} className="text-center text-white">
                                    <div className="animate-shiny-box">
                                        <div className="border-2 border-yellow-700 p-3 rounded-lg shadow-lg bg-gradient-to-r from-gray-900 via-black to-gray-900 hover:bg-black/50 transition-all duration-300">
                                            <h4 className="text-xl md:text-2xl font-bold font-sans italic text-yellow-400">{draw.drawName}</h4>

                                            {/* Display either fullPatti or number based on the pattern */}
                                            <div className="text-xl md:text-2xl font-bold">
                                                {draw.selectedPattern === "mumbai-pattern" && (
                                                    (() => {
                                                        const mumbaiDraw = todaysDraws[0].mumbaiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id);
                                                        if (mumbaiDraw) {
                                                            const openRoundPatti = mumbaiDraw.openRoundPatti;
                                                            const closeRoundPatti = mumbaiDraw.closeRoundPatti;
                                                            const fullPatti = getFullPatti(openRoundPatti, closeRoundPatti);

                                                            if (fullPatti) {
                                                                return <span className="text-yellow-200">{fullPatti}</span>;
                                                            } else if (openRoundPatti) {
                                                                return <span className="text-yellow-200">{`${openRoundPatti}x-xxx`}</span>;
                                                            } else {
                                                                return <span className="text-gray-400">xxx-xx-xxx</span>;
                                                            }
                                                        } else {
                                                            return <span className="text-gray-400">xxx-xx-xxx</span>;
                                                        }
                                                    })()
                                                )}

                                                {draw.selectedPattern === "delhi-pattern" && (
                                                    (() => {
                                                        const delhiDraw = todaysDraws[0].delhiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id);
                                                        if (delhiDraw) {
                                                            const delhiNumber = delhiDraw.number;

                                                            if (delhiNumber) {
                                                                return <span className="text-yellow-200">{delhiNumber}</span>;
                                                            } else {
                                                                return <span className="text-gray-400">xx</span>;
                                                            }
                                                        } else {
                                                            return <span className="text-gray-400">xx</span>;
                                                        }
                                                    })()
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <hr className="w-full my-8 border-t-2 border-yellow-700 shadow-[0_1px_3px_rgba(255,223,0,0.4)]"/>

                <img src={goldenImage} alt="" className="h-16 hover:scale-110 transition-transform duration-300 drop-shadow-[0_0_15px_rgba(255,223,0,0.3)]"/>

                {/* App Download Button */}
                <div className="w-full max-w-md mx-auto p-4 mb-8">
                    <div className="font-sans rounded-lg border-2 border-yellow-700 bg-gradient-to-r from-yellow-600 to-yellow-800 hover:from-yellow-700 hover:to-yellow-900 transition-all duration-300 shadow-[0_0_10px_rgba(255,223,0,0.3)]">
                        <a href={`https://drive.google.com/file/d/1zItpe0_4QOp2j1SSGF73Pn8elPoEx2HZ/view`}
                           target="_blank"
                           rel="noopener noreferrer"
                           className="flex flex-col items-center justify-center p-2 space-y-1">
                            <div className="text-2xl font-black text-black tracking-wider">
                                DOWNLOAD APP
                            </div>
                        </a>
                    </div>
                </div>

                {/* Today's Result Header */}
                <div className="text-2xl md:text-3xl font-bold bg-gradient-to-r from-yellow-600 to-yellow-800 rounded-lg px-6 py-2 shadow-lg hover:from-yellow-700 hover:to-yellow-900 transition-all duration-300 text-black tracking-wider">
                    TODAY'S RESULT
                </div>

                {/* Top Six Draws - Premium Section with Fixed Chart Button */}
                <div className="w-full mb-8 px-2 max-w-7xl">
                    {topSixMumbaiDraws.map(draw => {
                        const isHighlighted = highlightedDraws.includes(draw.id);
                        return (
                            <div key={draw.id}
                                 className={`mb-3 rounded-lg relative overflow-hidden group transform 
                                      transition-all duration-300 hover:scale-[1.02] 
                                      border-2 ${isHighlighted ? 'border-yellow-500 shadow-[0_0_20px_rgba(255,215,0,0.7)]' : 'border-yellow-700'} shadow-lg
                                      bg-gradient-to-r from-yellow-800/50 via-black to-yellow-800/50`}>

                                <div className="absolute top-0 left-0 w-full h-1.5 bg-yellow-500 shadow-[0_0_15px_rgba(255,215,0,0.7)]"></div>

                                <div className="absolute inset-0 bg-black/40 backdrop-blur-sm"></div>
                                <div className="relative p-3">
                                    <div className="flex flex-col sm:flex-row justify-between gap-2">
                                        {/* Left side with game name and timing */}
                                        <div className="flex flex-col items-start text-left">
                                            <h3 className={`text-3xl md:text-4xl font-bold ${isHighlighted ? 'text-yellow-300' : 'text-yellow-400'} font-sans italic tracking-wide`}>
                                                {draw.drawName}
                                            </h3>
                                            <div className="text-base md:text-lg text-yellow-300 mt-1 font-semibold">
                                                ⏰ {twentyFourHourToTwelveHourCovert(draw.startTime)} - {twentyFourHourToTwelveHourCovert(draw.closingTime)}
                                            </div>
                                        </div>

                                        {/* Right side with result and chart button */}
                                        <div className="flex items-center gap-4">
                                            <div className="text-[28px] sm:text-3xl font-bold">
                                                {todaysDraws.length > 0 && todaysDraws[0].mumbaiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id) ? (
                                                    <div className={`px-2 sm:px-4 py-2 bg-black/60 rounded-lg border-2 border-yellow-700
                                                            shadow-[0_0_15px_rgba(255,223,0,0.3)] hover:shadow-[0_0_25px_rgba(255,223,0,0.5)] transition-all duration-300`}>
                                                        {(getFullPatti(
                                                            todaysDraws[0].mumbaiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id).openRoundPatti,
                                                            todaysDraws[0].mumbaiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id).closeRoundPatti
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <div className="px-4 py-2 bg-black/60 rounded-lg border-2 border-yellow-700/50 shadow-lg">
                                                        xxx-xx-xxx
                                                    </div>
                                                )}
                                            </div>
                                            <Link to={`/chart/${draw.id}/false/m`}
                                                  className="absolute right-2 top-10 sm:relative sm:top-auto sm:right-auto bg-gradient-to-r from-yellow-600 to-yellow-800 
                                                      text-black font-bold px-3 py-1 sm:py-3 rounded-lg shadow-lg text-xl
                                                      hover:from-yellow-700 hover:to-yellow-900 transition-all duration-300 
                                                      hover:shadow-[0_0_15px_rgba(255,223,0,0.5)]
                                                      active:scale-95">
                                                Chart
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                    <div className="absolute bottom-0 left-0 w-full h-1.5 bg-yellow-500 shadow-[0_0_15px_rgba(255,215,0,0.7)]"></div>

                            </div>
                        )
                    })}
                </div>

                <hr className="w-full my-8 border-t-2 border-yellow-700 shadow-[0_1px_3px_rgba(255,223,0,0.4)]"/>

                <div className="flex flex-col font-bold justify-center text-3xl mb-0">
                    TODAY'S DRAW
                </div>

                {/* Mumbai Today's Result with Enhanced Styling */}
                <div className="todays-result w-full text-center max-w-6xl">
                    {mumbaiPatternDraws.length > 0 && (
                        <div className="border border-yellow-700 rounded-lg overflow-hidden">
                            {mumbaiPatternDraws.map(draw => {
                                const isHighlighted = highlightedDraws.includes(draw.id);
                                return (
                                    <div key={draw.id} 
                                         className={`border-t border-yellow-700/50 py-2 relative ${
                                             isHighlighted 
                                                ? 'bg-gradient-to-r from-yellow-800/60 via-yellow-900/50 to-yellow-800/60 shadow-[0_0_15px_rgba(255,215,0,0.5)]' 
                                                : 'bg-gradient-to-r from-gray-900 via-black to-gray-900'
                                         }`}>
                                        {isHighlighted && (
                                            <div className="absolute top-0 left-0 w-2 h-full bg-yellow-500"></div>
                                        )}
                                        <div className="flex flex-col items-center">
                                            <h3 className={`text-2xl ${isHighlighted ? 'text-yellow-200 font-extrabold' : 'text-yellow-300 font-bold'} font-sans italic`}>
                                                {draw.drawName} 
                                            </h3>
                                            {/* Increased font size and weight for timing */}
                                            <div className="text-lg md:text-lg font-extrabold text-blue-300">
                                                {twentyFourHourToTwelveHourCovert(draw.startTime)} - {twentyFourHourToTwelveHourCovert(draw.closingTime)}
                                            </div>
                                            <div className="text-[26px] font-extrabold mt-1">
                                                {todaysDraws.length > 0 && todaysDraws[0].mumbaiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id) ? (
                                                    <span className={`p-1 font-sans rounded border ${isHighlighted ? 'border-yellow-500 shadow-[0_0_8px_rgba(255,223,0,0.5)]' : 'border-yellow-700 shadow-[0_0_5px_rgba(255,223,0,0.3)]'} bg-black/30 text-yellow-200`}>
                                                        {getFullPatti(
                                                            todaysDraws[0].mumbaiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id).openRoundPatti,
                                                            todaysDraws[0].mumbaiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id).closeRoundPatti
                                                        )}
                                                    </span>
                                                ) : (
                                                    <span className="text-gray-400">--</span>
                                                )}
                                            </div>
                                        </div>
                                        <Link to={`/chart/${draw.id}/false/m`}
                                              className={`absolute right-2 top-1/2 -translate-y-1/2 bg-gradient-to-r 
                                                    ${isHighlighted ? 'from-yellow-500 to-yellow-700' : 'from-yellow-600 to-yellow-800'} 
                                                    text-black font-bold px-2 py-1 rounded 
                                                    hover:from-yellow-700 hover:to-yellow-900 shadow-lg transition-all duration-300`}>
                                            Chart
                                        </Link>
                                        {isHighlighted && (
                                            <div className="absolute top-0 right-0 w-2 h-full bg-yellow-500"></div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>

                {/* Delhi Today's Result with Enhanced Styling */}
                <div className="todays-result w-full text-center max-w-6xl mt-6 mb-20">
                    <div className="text-2xl bg-gradient-to-r from-yellow-600 to-yellow-800 text-black font-bold py-1 rounded-t-lg shadow-lg border-b border-yellow-950">
                        Delhi Bazaar
                    </div>
                    {delhiPatternDraws.length > 0 && (
                        <div className="border border-yellow-700 rounded-b-lg overflow-hidden">
                            {delhiPatternDraws.map(draw => {
                                const isHighlighted = highlightedDraws.includes(draw.id);
                                return (
                                    <div key={draw.id} 
                                         className={`border-t border-yellow-700/50 py-2 relative ${
                                             isHighlighted 
                                                ? 'bg-gradient-to-r from-yellow-800/60 via-yellow-900/50 to-yellow-800/60 shadow-[0_0_15px_rgba(255,215,0,0.5)]' 
                                                : 'bg-gradient-to-r from-gray-900 via-black to-gray-900'
                                         }`}>
                                        {isHighlighted && (
                                            <div className="absolute top-0 left-0 w-2 h-full bg-yellow-500"></div>
                                        )}
                                        <div className="flex flex-col items-center">
                                            <h3 className={`text-xl ${isHighlighted ? 'text-yellow-200 font-extrabold' : 'text-yellow-300 font-bold'} italic`}>
                                                {draw.drawName}
                                                {isHighlighted && <span className="text-xs ml-2 bg-yellow-500 text-black px-1.5 py-0.5 rounded-full font-bold">HOT</span>}
                                            </h3>
                                            {/* Increased font size and weight for timing */}
                                            <div className="text-lg md:text-lg font-bold text-blue-300">
                                                {twentyFourHourToTwelveHourCovert(draw.startTime)}
                                            </div>
                                            <div className="text-2xl md:text-3xl font-bold mt-1">
                                                {todaysDraws.length > 0 && todaysDraws[0].delhiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id) ? (
                                                    <span className={`p-1 font-sans rounded border ${isHighlighted ? 'border-yellow-500 shadow-[0_0_8px_rgba(255,223,0,0.5)]' : 'border-yellow-700 shadow-[0_0_5px_rgba(255,223,0,0.3)]'} bg-black/30 text-yellow-200`}>
                                                        {todaysDraws[0].delhiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id).number}
                                                    </span>
                                                ) : (
                                                    <span className="text-gray-400">--</span>
                                                )}
                                            </div>
                                        </div>
                                        <Link to={`/chart/${draw.id}/false/d`}
                                              className={`absolute right-2 top-1/2 -translate-y-1/2 bg-gradient-to-r 
                                                    ${isHighlighted ? 'from-yellow-500 to-yellow-700' : 'from-yellow-600 to-yellow-800'} 
                                                    text-black font-bold px-2 py-1 rounded 
                                                    hover:from-yellow-700 hover:to-yellow-900 shadow-lg transition-all duration-300`}>
                                            Chart
                                        </Link>
                                        {isHighlighted && (
                                            <div className="absolute top-0 right-0 w-2 h-full bg-yellow-500"></div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
                
                {/* Add SEOKeywords component at the bottom as footer */}
                <SEOKeywords />
            </div>

            {/* Refresh Button with Enhanced Styling */}
            <button
                className="fixed bottom-4 right-4 text-3xl text-white font-bold bg-gradient-to-r from-blue-800 to-blue-600 rounded-lg px-4 py-1 shadow-lg hover:from-blue-700 hover:to-blue-500 transition-all duration-300 border border-blue-900"
                onClick={() => {
                    refreshData()
                }}
            >
                Refresh
            </button>

            {/* Telegram Button with Enhanced Styling */}
            <div className={"fixed bottom-4 left-4"}>
                <div
                    className="text-3xl text-white font-bold bg-gradient-to-r from-blue-800 to-blue-600 rounded-lg shadow-lg hover:from-blue-700 hover:to-blue-500 border border-blue-900 transition-all duration-300">
                    <div className="whatsapp-link text-center">
                        <a
                            href="https://t.me/GOLDEN_BOMBAY_MATKA"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-center text-2xl text-white hover:text-gray-200 transition-colors p-2"
                        >
                            <img src={telegramLogo} alt="Telegram Logo" className="w-10 h-10"/>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export const twentyFourHourToTwelveHourCovert = (string) => {
    const [hours, minutes] = string.split(":");
    const formattedHours = hours.padStart(2, '0');
    const formattedMinutes = minutes.padStart(2, '0');
    const period = formattedHours >= 12 ? 'PM' : 'AM';
    const displayHours = formattedHours > 12 ? formattedHours - 12 : formattedHours;
    return `${displayHours}:${formattedMinutes} ${period}`;
}



export const getFullPatti = (open, close) => {
    const openNumber = parseInt(open, 10);
    const closeNumber = parseInt(close, 10);

    const openDigits = openNumber.toString().split('').map(Number);
    const closeDigits = closeNumber.toString().split('').map(Number);

    const openSum = openDigits.reduce((a, b) => a + b, 0);
    const closeSum = closeDigits.reduce((a, b) => a + b, 0);

    const openLastDigit = openSum.toString().slice(-1);
    const closeLastDigit = closeSum.toString().slice(-1);

    if (!close) {
        return `${open} - ${openLastDigit}x - xxx`;
    } else if (open && close) {
        return `${open} - ${openLastDigit}${closeLastDigit} - ${close}`;
    } else {
        return '';
    }
}

const MarqueeGroup = ({ isReverse, arrayOfObjects }) => (
    <div className={`marquee__group ${isReverse ? 'marquee--reverse' : ''} *:text-black *:border *:border-zinc-300 *:rounded-full *:px-4 *:py-1.5 *:text-md *:font-medium `}>
        {arrayOfObjects.map((item, index) => (
            <div key={index} className="sm:p-2 my-8 sm:my-16 font-sans rounded-xl border-2 border-amber-800 shadow-[0_0_15px_rgba(255,215,0,0.3)]">
                <div className={"text-2xl sm:text-4xl font-semibold px-5 pt-2 text-amber-500"}>{item.title}</div>
                <div className={"text-center text-2xl sm:text-4xl font-bold text-yellow-400 px-4 pb-2"} style={{letterSpacing: "6px"}}>{item.content}</div>
            </div>
        ))}
    </div>
);

const PattiMarqueeGroup = ({ isReverse, arrayOfObjects }) => (
    <div className={`marquee__group ${isReverse ? 'marquee--reverse' : ''}`}>
        {arrayOfObjects.map((item, index) => (
            <div key={index} className="p-4 my-8 font-sans rounded-xl border-2 border-amber-800 bg-black shadow-[0_0_15px_rgba(255,215,0,0.3)]">
                <div className="text-xl sm:text-2xl font-semibold text-amber-400 mb-2">{item.title}</div>
                <div className="grid grid-cols-3 gap-2">
                    {item.content.split(',').map((number, idx) => (
                        <div key={idx} className="text-center text-lg sm:text-xl font-bold text-white bg-black/60 rounded-lg p-2 border border-amber-700">
                            {number.trim()}
                        </div>
                    ))}
                </div>
            </div>
        ))}
    </div>
);
