// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'; // Import getFirestore function
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBYxhzRhErVef9OLb5waNELjobk7ccaHok",
    authDomain: "platinummatka.firebaseapp.com",
    databaseURL: "https://platinummatka-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "platinummatka",
    storageBucket: "platinummatka.appspot.com",
    messagingSenderId: "596893258609",
    appId: "1:596893258609:web:4e64ef97601a4cb0aadcd0",
    measurementId: "G-V91960RM5N"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics
const analytics = getAnalytics(app);

export const functions = getFunctions(app);

// Get the authentication instance
export const db = getFirestore(app); // Initialize the Firestore database instance
export { analytics }; // Export analytics instance
