import React from 'react';

const SEOKeywords = () => {
    const keywords = [
        "matka king live",
        "golden gaming result today",
        "golden news game result today",
        "golden gaming result today live",
        "golden matka news",
        "today milan day",
        "milan day today matka",
        "golden game result",
        "lottery result today",
        "golden matka result",
        "golden satta matka",
        "golden chart matka",
        "golden night matka",
        "golden satta matka bajar",
        "disawar satta bazar 2025",
        "online satta",
        "satta matka live",
        "live gali satta king",
        "gali desawar satta live",
        "satta king live",
        "live result",
        // Additional keywords
        "Kalyan live result",
        "Kalyan chart",
        "Madhur chart",
        "Madhur result",
        "Disawar live result",
        "Gaziyabad number",
        "Gaziyabad live result",
        "Gali chart",
        "Goa chart",
        "Goa live result",
        "Rajdhani chart",
        "Rajdhani live",
        "Rajdhani matka",
        "Satta live",
        "Dp result",
        "Sridevi chart"
    ];

    return (
        <footer className="w-full bg-gradient-to-b from-black to-[#092019] py-6 mt-8 border-t border-yellow-600/50">
            <div className="container mx-auto px-2">
                <p className="text-center text-yellow-400 text-sm md:text-base font-bold mb-4 tracking-wider shadow-[0_0_10px_rgba(255,215,0,0.5)]">
                    Golden News: March 2025 | Gali Result | Satta Result | Golden Matka
                </p>
                
                <div className="flex flex-wrap justify-center gap-[2px] md:gap-1 max-w-7xl mx-auto">
                    {keywords.map((keyword, index) => (
                        <div 
                            key={index} 
                            className="inline-block text-yellow-300 text-xs md:text-sm px-2 py-1 
                                     bg-black/50 rounded border border-yellow-600 
                                     shadow-[0_0_10px_rgba(255,215,0,0.3)] hover:shadow-[0_0_15px_rgba(255,215,0,0.6)]
                                     hover:border-yellow-400 transition-all duration-300"
                        >
                            {keyword}
                        </div>
                    ))}
                </div>
                
                <div className="text-center text-yellow-400/70 text-xs mt-6 font-medium">
                    &copy; {new Date().getFullYear()} DPANNA | Golden Group Results & Updates
                </div>
            </div>
        </footer>
    );
};

export default SEOKeywords;
